/* Make the icons larger */
.statistics-icon {
    height: 5vw;
    width: auto;
}
@media all and (max-width: 767px) {
    .statistics-icon {
        height: 10vw;
        width: auto;
    }
}

/* Make the box a bit smaller */
.statistics-box {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 2.5vw;
    min-height: 30vh;
} 
@media all and (max-width: 767px) {
    .statistics-box {
        min-height: 0vh;
    }
}

/* Subtitle for the graph */
.subtitle-text {
    font-size: 0.7vw !important;
}
@media all and (max-width: 767px) {
    .subtitle-text {
        font-size: 0.5rem !important;
    }
}

/* Adjust the font size */
.statistics-box p {
    font-size: 1.1vw;
}
@media all and (max-width: 767px) {
    .statistics-box p {
        font-size: 1rem;
    }
}

/* Make the concern bar fit */
.concern-bar {
    width: 100%;
    height: auto;
}

/* Titles for statistics should be a bit smaller */
.statistics-title {
    font-size: 1.75vw;
}
@media all and (max-width: 767px) {
    .statistics-title {
        font-size: 1.5rem;
    }
}