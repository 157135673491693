/* Style the active toggle */
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    background-color: #bbbbbb;
    border-color: black;
    color: black;
}

/* Style the inactive toggle */
.btn-secondary {
    background-color: transparent;
    border-color: black;
    color: black;
}
.btn-secondary:hover {
    background-color: #bbbbbb;
    border-color: black;
    color: black;
}