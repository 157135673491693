.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1031;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.exit-pop {
    min-height: 14vh;
    min-width: 100%;
    justify-content: right;
    padding: 2vh 2vw 2vh 2vw !important;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    background: black;
    margin: 0;
}

.exit-pop div p {
    width: 100%;
    text-align: right;
}

.img-pop {
    margin-top: 19vh;
    min-height: 86vh;
    min-width: 100%;
}

.img-box {
    max-width: 35vw;
}
@media all and (max-width: 767px) {
    .img-box {
        max-width: 85vw;
    }
}

/* Make the type description in white */
.type-desc {
    color: white;
    font-size: 2rem;
}

/* Return button */
.return-btn {
    height: 3rem;
    color: black;
    background-color: #BFBFBF;
    border-color: #BFBFBF;
    font-family: 'Monument';
    border-width: 2px;
}
.return-btn:hover {
    color: white;
    background-color: #BFBFBF !important;
    border-color: #BFBFBF !important;
}
@media all and (max-width: 767px) {
    .return-btn {
        height: 4rem;
        font-size: 1.25rem;
    }
}