/* Make the container center the gif */
.loading-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-img {
    width: 25%;
    height: auto;
}