.email-box {
    background-color: rgba(128, 194, 220, 0.5);
    border-radius: 1vw;
    min-height: 35vh;
    width: 75%;
    flex-direction: column;
    padding: 5vw;
}
@media all and (max-width: 767px) {
    .email-box {
        width: 100%;
    }
}

/* Make the email form smaller */
.email-form {
    width: 80%;
}

/* Make the email submit form match the top */
.email-submit {
    background-color: transparent !important;
    border: 2px solid rgba(0, 0, 0, 0.75) !important;
    border-radius: 5px 0px 0px 5px !important;
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}
.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
}

/* Email button */
.email-btn {
    color: white !important;
    background-color: black !important;
    border-color: black;
}
.email-btn:hover {
    border-color: black !important;
}