/* Silka for filter titles */
.filters-form-control {
    color: black !important;
    border: 0 solid transparent !important;
    padding-left: 2vw !important;
    border: 2px solid rgba(0, 0, 0, 0.75) !important;
    border-radius: 5px !important;
    background-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    background-image: url(../images/arrow.png);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: right;
    background-origin: content-box;
    font-family: 'SilkaMono-Regular';
    font-weight: 500;
}

/* Hover on the paragraph */
.hover-text {
    cursor: pointer;
}