/* Default for body */
@font-face {
    font-family: 'Monument';
    src: local('Monument'), url(../fonts/MonumentExtended-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'SilkaMono-Regular';
    src: local('SilkaMono-Regular'), url(../fonts/SilkaMono-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'SilkaMono-Light';
    src: local('SilkaMono-Light'), url(../fonts/SilkaMono-Light.otf) format('opentype');
}

body {
    margin: 0;
    font-family: SilkaMono-Regular, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(255, 255, 255, 0);
    width: 100vw;
}