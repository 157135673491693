.legend-size {
    min-height: 5vh;
}

.legend-bar {
    width: 33%;
    height: auto;
}

.legend-text {
    font-size: 1rem;
}

@media all and (max-width: 767px) {
    .legend-text {
        font-size: 0.75rem;
    }
}
