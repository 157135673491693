/* Make the footer an image */
.footer {
    background-image: url('../images/footer.png');
    background-size: 100% auto;
    background-position: center;
}

/* Bold text for footer */
.footer-text-bold {
    color: white;
    font-size: 1rem;
    font-weight: 700;
}

.footer-text-bold a {
    color: white;
    text-decoration: underline;
}

.footer-text-bold a:hover {
    color: white;
}

.no-underline a {
    text-decoration: none !important;
    font-weight: 700;
}

/* Normal text for footer */
.footer-text-normal {
    color: white;
    font-size: 1rem;
    font-weight: 300;
}

.footer-text-normal a {
    color: white;
    text-decoration: underline;
}

.footer-text-normal a:hover {
    color: white;
}

/* Logos */
.footer-up-to-us {
    height: 3rem;
    width: auto;
    margin-bottom: 0.25rem;
}
@media all and (max-width: 767px) {
    .footer-up-to-us {
        height: 2rem;
    }
}

.footer-intrvl-line {
    height: 1.5rem;
    margin-left: 1.5rem;
    width: auto;
}
@media all and (max-width: 767px) {
    .footer-intrvl-line {
        height: 1rem;
    }
}

.footer-intrvl-name {
    height: 1.5rem;
    margin-left: 0.5rem;
    width: auto;
}
@media all and (max-width: 767px) {
    .footer-intrvl-name {
        height: 1rem;
    }
}

/* Logo column */
.footer-logo-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}