/* Account for the navigation */
.page {
    padding: 18vh 4vw 2vh 4vw !important;
    width: 100vw;
}

/* No nav version */
.page-frame {
    padding: 2vh 4vw 2vh 4vw !important;
    width: 100vw;
}

/* Monument for page titles */
.page-title {
    font-family: Monument, sans-serif !important;
}

/* Choropleth height must be chosen for dynamic rendering */
.choropleth-size {
    width: 100%;
    height: 30vw;
    cursor: pointer;
}
@media all and (max-width: 767px) {
    .choropleth-size {
        height: 50vw;
        padding-left: 0;
        padding-right: 0;
    }
}

.choropleth-title {
    flex-direction: column;
}

/* Stat section */
.stat-section {
    background-color: #F9F9F9;
    border-radius: 1vw;
    padding: 1vw;
}

/* Link */
.link-font {
    color: black;
}

.link-font:hover {
    color: black;
    text-decoration: none;
}

/* Control overflow for pop ups */
.pop-up-control {
    overflow: hidden;
}