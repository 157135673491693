/* Share button */
.share-btn {
    height: 4.5rem;
    color: black;
    background-color: transparent;
    width: 15rem;
    border-width: 2px;
    font-family: 'Monument';
}
.share-btn:hover {
    color: black;
}
@media all and (max-width: 767px) {
    .share-btn {
        width: 100%;
    }
}

/* Colors for the buttons */
.orange-btn {
    border-color: #E69538;
}
.orange-btn:hover {
    background-color: #E69538 !important;
    border-color: #E69538 !important;
}
.grey-btn {
    border-color: #BFBFBF
    ;
}
.grey-btn:hover {
    background-color: #BFBFBF !important;
    border-color: #BFBFBF !important;
}
.blue-btn {
    border-color: #acd0e0;
    background-color: #acd0e0;
}
.blue-btn:hover {
    background-color: #acd0e0 !important;
    border-color: #acd0e0 !important;
}
.blue-btn-no-hover:hover {
    text-decoration: none !important;
}

/* Icons in the buttons */
.share-icon {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
}

/* Top share button */
.share-top-btn {
    color: black;
    background-color: transparent;
    border-width: 2px;
    font-family: 'Monument';
}
.share-top-btn div p {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
}
.share-top-btn:hover {
    color: black;
}
@media all and (max-width: 767px) {
    .share-top-btn {
        width: 100%;
    }
}