/* Adjust the navbar to fit our branding */
.navbar-dynamic {
    height: 14vh;
    width: 100%;
    padding: 2vh 4vw 2vh 4vw !important;
    background-color: black;
    background-size: 100% auto;
    background-position: center;
}

/* Size and pad the navbar */
.navbar-logo-width {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media all and (max-width: 767px) {
    .navbar-logo-width {
        width: 100%;
        justify-content: center;
    }
}

/* Logos */
.navbar-up-to-us {
    height: 3vw;
    width: auto;
    margin-right: 1.5vw;
}
@media all and (max-width: 767px) {
    .navbar-up-to-us {
        height: 2rem;
        margin-bottom: 0.25rem;
        margin-right: 2vh;
    }
}

.sep-line {
    height: 80%;
    width: 1px;
    background-color: white;
}

.navbar-brand {
    height: 90%;
}

.navbar-intrvl-line {
    height: 1.75vw;
    margin-left: 1.5vw;
    width: auto;
}
@media all and (max-width: 767px) {
    .navbar-intrvl-line {
        height: 1rem;
        margin-left: 2vh;
        /* margin-bottom: 0.2vh; */
    }
}

.navbar-intrvl-name {
    height: 1.75vw;
    margin-left: 1vw;
    width: auto;
}
@media all and (max-width: 767px) {
    .navbar-intrvl-name {
        height: 1rem;
        margin-left: 1vh;
        /* margin-bottom: 0.2vh; */
    }
}

/* Donate button */
.donate-btn {
    height: 3rem;
    color: black;
    background-color: #acd0e0;
    border-color: #acd0e0;
    font-family: 'Monument';
    border-width: 2px;
}
.donate-btn:hover {
    color: white;
    background-color: #acd0e0 !important;
    border-color: #acd0e0 !important;
}
@media all and (max-width: 767px) {
    .donate-btn {
        height: 2rem;
        font-size: 0.75rem;
    }
}

/* Fix brand */
.navbar-brand {
    margin-right: 0 !important;
}