.insights-box {
    background-image: url('../images/expanded_insights.png');
    min-height: 75vh;
    width: 100vw;
    flex-direction: column;
    background-position: center center;
    color: white;
    background-size: 100% 100%;
}
@media all and (max-width: 767px) {
    .insights-box {
        height: 37.5vh;
    }
}

/* Inquire button */
.inquire-btn {
    height: 3rem;
    color: black;
    background-color: #E69538;
    border-color: #E69538;
    font-family: 'Monument';
    border-width: 2px;
    width: 15rem;
}
.inquire-btn:hover {
    color: white;
    background-color: #E69538 !important;
    border-color: #E69538 !important;
}
@media all and (max-width: 767px) {
    .inquire-btn {
        height: 2rem;
        font-size: 0.75rem;
    }
}

.form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.form-container form {
    width: 75%;
}

.form-container form div .form-control {
    background-color: transparent;
    color: white;
}

.form-container form div .form-control::placeholder {
    opacity: 1;
    color: white;
}